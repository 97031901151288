<template>
  <div class="basket-item">
    <div class="area-separator"/>

    <div class="area-image">
      <!-- <img v-if="item.product.image" :src="item.product.image" :alt="item.product.name"/> -->
      <aspect-ratio ratio="4x3" class="is-fallback-image">
        <b-icon icon="images"/>
      </aspect-ratio>
    </div>

    <div class="area-description">
      <p class="mb-0"><span class="item-name">{{ item.productName }}</span></p>
      <p class="mb-0"><span>Artikelnummer:</span>{{ item.customerProductId }}</p>
      <p class="mb-0" v-if="item.ean"><span>Ean:</span>{{ item.ean }}</p>
      <p class="mb-0"><span>buzzle Id:</span>{{ item.productId }}</p>
    </div>

     <div class="area-quantity">
        <span>{{ itemQuantity }}</span>
    </div>

    <div class="area-price">
      <item-price :productId="item.productId" :price="item.price.unitPrice" :currency="item.price.currency" alginH="end" hideVatInfo inherit/>
    </div>

     <div class="area-vat">
      <item-price :productId="item.productId" :price="item.price.vatAmount" :currency="item.price.currency" alginH="end" hideVatInfo inherit/>
    </div>

    <div class="area-totalprice">
      <item-price :productId="item.productId" :price="item.price.price" :currency="item.price.currency" alginH="end" hideVatInfo inherit/>
    </div>

    <div class="area-separator"/>
  </div>
</template>

<script>
import ItemPrice from '@/components/order/ItemPrice'
import AspectRatio from '@/components/AspectRatio'

export default {
  name: 'BasketItem',
  components: {
    ItemPrice,
    AspectRatio
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemQuantity: this.item.quantity
    }
  }
}
</script>

<style lang="scss">
$basketitem-separator-gap-y: $spacer * 0.4 !default;
$basketitem-separator-border: $border-width solid $border-color !default;

$basketitem-image-width: 100px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $light !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

.order-basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;
      }

      .area-image {
        img,
        .is-fallback-image {
          display: block;
          margin: 0;
          width: $basketitem-image-width;
          max-width: none;

          .bi {
            display: block;
            margin: auto;
            width: $basketitem-img-fallback-size;
            height: $basketitem-img-fallback-size;
            color: $basketitem-img-fallback-color;
          }
        }
      }

      .area-description {
        .item-name {
          font-weight: $basketitem-description-name-font-weight;
          text-transform: $basketitem-description-name-text-transform;
        }
      }

      .area-configuration {
        .item-configuration {
          display: flex;
          flex-wrap: wrap;
          margin: ($basketitem-configuration-gap-y * -0.5) ( $basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }
      }

      .area-price,
      .area-vat,
      .area-totalprice {
        white-space: nowrap;
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      + .basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
</style>

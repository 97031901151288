<template>
  <div class="order-basket">
    <b-row>
      <b-col :[$root.md.key]="12" tag="article">
        <div class="basket-items">
          <div class="items-header">
            <div class="area-description">{{ $t("components.order.basket.header.description") }}</div>
            <div class="area-quantity">{{ $t("components.order.basket.header.quantity") }}</div>
            <div class="area-price">{{ $t("components.order.basket.header.price") }}</div>
            <div class="area-vat">{{ $t("components.order.basket.header.vat") }}</div>
            <div class="area-totalprice">{{ $t("components.order.basket.header.totalprice") }}</div>
          </div>

          <div class="items-main">
            <basket-item v-for="(item, index) in lineItems" :key="`BasketItem_${index}`" :item="item" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BasketItem from "@/components/order/BasketItem";

export default {
  name: "OrderBasket",
  components: {
    BasketItem,
  },
  props: {
    lineItems: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
// mobile

$orderbasket-emptymessage-content-text-align: inherit !default;

$orderbasket-items-grid-column-gap: $grid-gutter-width * 0.5 !default;
$orderbasket-items-grid-row-gap: $spacer * 0.4 !default;
$orderbasket-items-grid-columns: [start] auto 4fr auto auto auto [end] !default;
$orderbasket-items-grid-rows: min-content auto min-content !default;
$orderbasket-items-grid-area-gap-y: $spacer !default;

$orderbasket-items-header-font-size: $font-size-sm !default;
$orderbasket-items-header-color: $dark !default;
$orderbasket-items-header-line-height: 1.1 !default;

$orderbasket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$orderbasket-items-footer-actions-gap-x: $spacer * 2 !default;

.order-basket {
  .basket-emptymessage {
    margin-top: 0 !important;

    .emptymessage-image {
    }

    .emptymessage-content {
      text-align: $orderbasket-emptymessage-content-text-align;
    }
  }

  .basket-items {
    display: grid;
    column-gap: $orderbasket-items-grid-column-gap;
    row-gap: $orderbasket-items-grid-row-gap;
    grid-template-columns: $orderbasket-items-grid-columns;
    grid-template-rows: $orderbasket-items-grid-rows;

    [class*="area-"] {
      grid-row: auto;
    }

    .items-header {
      display: contents;
      font-size: $orderbasket-items-header-font-size;
      color: $orderbasket-items-header-color;
      line-height: $orderbasket-items-header-line-height;

      .area-description {
        grid-column: start / 3;
      }
    }

    .area-image,
    .area-description,
    .area-configuration,
    .area-quantity .area-vat {
      margin-bottom: 0;
    }

    .area-separator {
      grid-row-gap: 0;
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-image {
      grid-column: start;
      grid-row: auto / span 2;
      justify-self: stretch;
    }

    .area-description {
      grid-column: 2;
    }

    .area-quantity {
      grid-column: 3;
      justify-self: center;
    }

    .area-price {
      grid-column: 4;
      justify-self: flex-end;
    }

    .area-vat {
      grid-column: 5;
      justify-self: flex-end;
    }

    .area-totalprice {
      grid-column: 6;
      justify-self: flex-end;
    }

    .area-services {
      grid-column: start / end;
      justify-self: flex-end;
    }

    .items-main {
      display: contents;
    }

    .items-footer {
      display: contents;

      .area-basketactions-start {
        grid-column: start / 4;
      }

      .area-basketactions-end {
        grid-column: 4 / end;
        justify-self: flex-end;
        margin-top: 0;
      }

      .footer-actions {
        display: flex;
        margin: ($orderbasket-items-footer-actions-gap-y * -0.5) ($orderbasket-items-footer-actions-gap-x * -0.5);

        a {
          display: block;
          padding: ($orderbasket-items-footer-actions-gap-y * 0.5) ($orderbasket-items-footer-actions-gap-x * 0.5);
        }
      }
    }
  }
}
</style>

<template>
  <div class="order-detail">
    <h2 class="sr-only">{{ $t('views.orders.detail.title') }}</h2>

    <loader v-if="is.loading"/>

    <template v-else>
      <div class="order-header">
        <dl>
          <div>
            <dt>{{ definition.$properties.orderId.translations[$i18n.locale].name }}</dt>
            <dd>{{ entity.orderId }}</dd>
          </div>

          <div>
            <dt>{{ $t('views.orders.detail.location.title') }}</dt>
            <dd>{{ entity.configuration.location.name }}</dd>
          </div>

          <div>
            <dt>{{ definition.$properties.orderIdInPosSystem.translations[$i18n.locale].name }}</dt>
            <dd>{{ entity.orderIdInPosSystem }}</dd>
          </div>

          <div>
            <dt><span v-html="entity.priceDetail.totalPriceLabel[$i18n.locale]"></span></dt>
            <dd>{{ $n(entity.totalPrice, 'currency') }}</dd>
          </div>

        </dl>
      </div>

     <order-basket :lineItems="entity.lineItems"/>

      <order-configuration :summary="summary" :configuration="entity.configuration"/>
    </template>
  </div>
</template>

<script>
import { ORDER_STATES, ORDER_STATES_BADGES_VARIANTS, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import { valueFormatter } from '@/assets/js/helper/entity'

import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'
import OrderBasket from '@/components/order/OrderBasket'
import OrderConfiguration from '@/components/order/OrderConfiguration'

export default {
  name: 'Orders.Detail',
  mixins: [MixinEntityBase],
  components: {
    Loader,
    OrderConfiguration,
    OrderBasket
  },
  data () {
    return {
      orderStateBadgeVariants: Object.keys(ORDER_STATES)
        .reduce((variants, sKey) => Object.assign(variants, { [ORDER_STATES[sKey]]: ORDER_STATES_BADGES_VARIANTS[sKey] }), {})
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eParent.key}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    },
    summary () {
      const priceDetails = this.entity.priceDetail || {}

      return []
        .concat(priceDetails.priceLines ?? [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel ?? {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$i18n.locale]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    }
  },
  methods: {
    valueFormatter
  }
}
</script>

<style lang="scss">
.order-detail {
  .order-header {
    padding: $spacer;
    background-color: $gray-200;

    dl {
      display: grid;
      grid-gap: $spacer * 0.75;
      grid-auto-flow: column;
      grid-template-columns: auto;
      margin: 0;

      dd {
        margin: 0;
      }
    }
  }

  .order-basket {
    margin-top: $spacer * 2;
  }

  .order-configuration {
    margin-top: $spacer * 2;
  }
}
</style>
